<template>
  <div id="vacancies" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">VACANTES EN PROCESO</v-row>
      <v-row no-gutters class="datos pa-0 mt-5" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="vacancies"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Vacantes por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.Id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">
                    {{ item.puestoVacante.areaReporta }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.puestoVacante.nombrePuesto }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.estatus.estatus }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Empresa</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      selectedItem: null,
      idEmpleado: localStorage.empleadoIdGlobal,
      headers: [
        {
          text: "Área",
          align: "center",
          value: "puestoVacante.areaReporta",
        },
        {
          text: "Puesto",
          align: "center",
          value: "puestoVacante.nombrePuesto",
        },
        {
          text: "Estatus",
          align: "center",
          value: "estatus.estatus",
        },
      ],
      vacancies: [],
      respuesta: "",
      confirmation: false,
      areas: [],
    };
  },
  methods: {
    area() {
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.areas = response.data;
          this.vacancies.forEach((value, index) => {
            this.areas.forEach((element, index) => {
              if (value.puestoVacante.areaReportaId == element.id)
                value.puestoVacante.areaReporta = element.nombreArea;
            });
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(
          Server + "/requisicionesVacantes/por-empleado/" + this.idEmpleado,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.vacancies = response.data;
          this.show = false;
          this.area();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>